import { createStore } from 'vuex'
import pluginUser from './modules/pluginUserStore'
import zoho from './modules/zohoStore'
import deal from './modules/dealStore'
import lead from './modules/leadStore'
import hierarchyFolders from './modules/hierarchyFoldersStore'
import dropbox from './modules/dropboxStore'
import leadConversion from './modules/leadConversionStore'

export default createStore({
    modules: {
        pluginUser,
        zoho,
        deal,
        lead,
        dropbox,
        hierarchyFolders,
        leadConversion,
    },
})
