import axios from 'axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        hierarchyFoldersTree: {
            deals: [],
            leads: [],
        },
        componentData: {
            renamingFolderData: null,
        },
    },

    getters: {
        hierarchyFoldersTree(state) {
            return state.hierarchyFoldersTree
        },
        renamingFolderData(state) {
            return state.renamingFolderData
        },
    },

    mutations: {
        setHierarchyFoldersTree(state, payload) {
            state.hierarchyFoldersTree[payload.moduleName] = payload.data
        },
        setRenamingFolderData(state, payload) {
            state.renamingFolderData = payload
        },
    },

    actions: {
        setHierarchyFoldersTree(context, payload) {
            context.commit('setHierarchyFoldersTree', payload)
        },
        async fetchHierarchyFoldersTree(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/getHierarchyFoldersTree`
            var params = {
                moduleName: payload.moduleName,
            }
            return await axios.get(link, { params }).then((res) => {
                context.commit('setHierarchyFoldersTree', {
                    moduleName: payload.moduleName,
                    data: res.data.data,
                })
            })
        },
        async setHierarchyFoldersTree(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/setHierarchyFoldersTree`
            // var params = {
            //     moduleName: payload.moduleName,
            //     foldersTree: JSON.stringify(payload.foldersTree),
            // };
            return await axios
                .post(link, {
                    moduleName: payload.moduleName,
                    // foldersTree: JSON.stringify(payload.foldersTree),
                    foldersTree: payload.foldersTree,
                })
                .then((res) => {
                    context.commit('setHierarchyFoldersTree', {
                        moduleName: payload.moduleName,
                        data: res.data.data,
                    })
                })
        },
        setRenamingFolderData(context, payload) {
            context.commit('setRenamingFolderData', payload)
        },
    },
}
