import axios from 'axios'

export default {
    namespaced: true,

    state: {
        leadZoho: {},
        leadFolderInfo: {},
        leadCurrentFolderContents: {
            entries: [],
            cursor: null,
            has_more: null,
        },
    },

    getters: {
        leadZoho(state) {
            return state.leadZoho
        },
        leadFolderInfo(state) {
            return state.leadFolderInfo
        },
        leadCurrentFolderContents(state) {
            return state.leadCurrentFolderContents
        },
    },

    mutations: {
        setLeadZoho(state, payload) {
            state.leadZoho = payload
        },
        setLeadFolderInfo(state, payload) {
            state.leadFolderInfo = payload
        },
        setLeadCurrentFolderContents(state, payload) {
            state.leadCurrentFolderContents.entries = payload.entries.sort((a, b) => {
                if (a['.tag'] == b['.tag']) {
                    return a.name.localeCompare(b.name)
                } else {
                    return a['.tag'] == 'folder' ? -1 : 1
                }
            })
            state.leadCurrentFolderContents.cursor = payload.cursor
            state.leadCurrentFolderContents.has_more = payload.has_more
        },
        mergeAdditionalLoadedFolderContents(state, payload) {
            state.leadCurrentFolderContents.entries = [
                ...state.leadCurrentFolderContents.entries,
                ...payload.entries.sort((a, b) => {
                    if (a['.tag'] == b['.tag']) {
                        return a.name.localeCompare(b.name)
                    } else {
                        return a['.tag'] == 'folder' ? -1 : 1
                    }
                }),
            ]
            state.leadCurrentFolderContents.cursor = payload.cursor
            state.leadCurrentFolderContents.has_more = payload.has_more
        },
    },

    actions: {
        async fetchLeadZoho(context, payload) {
            await ZOHO.CRM.API.getRecord({
                Entity: 'Leads',
                RecordID: payload,
            }).then((response) => {
                context.dispatch('setLeadZoho', response.data[0])
            })
        },
        async fetchLeadFolderInfo(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload}`
            var params = {
                moduleName: 'leads',
            }
            await axios
                .get(link, { params })
                .then((res) => {
                    context.dispatch('setLeadFolderInfo', res.data.mainFolder.data)
                })
                .catch((e) => {
                    if (e.response.status == 404) {
                        context.dispatch('setLeadFolderInfo', {})
                    }
                })
        },
        async createLeadFoldersFromSettings(context) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/createLeadFoldersFromSettings`
            var payload = {
                lead: context.getters.leadZoho,
            }

            return await axios.post(link, payload).then(() => {})
        },
        async fetchFolderContents(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/getFolderContents`
            var params = {
                moduleName: 'leads',
                folderId: payload.folderId,
            }

            return await axios.get(link, { params }).then((r) => {
                context.commit('setLeadCurrentFolderContents', r.data)
                return r.data
            })
        },
        async fetchParentFolderContents(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/getParentFolderContents`
            var params = {
                moduleName: 'leads',
                folderId: payload.folderId,
            }

            return await axios.get(link, { params }).then((r) => {
                context.commit('setLeadCurrentFolderContents', r.data.contents)
                return r.data
            })
        },
        async loadMoreContents(context, payload) {
            const params = {
                moduleName: 'leads',
                cursor: payload.cursor,
                folderId: payload.folderId,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/loadMoreContents`
            return await axios.get(link, { params }).then((r) => {
                context.commit('mergeAdditionalLoadedFolderContents', r.data.contents)
                return r.data
            })
        },
        setLeadZoho(context, payload) {
            context.commit('setLeadZoho', payload)
        },
        setLeadFolderInfo(context, payload) {
            context.commit('setLeadFolderInfo', payload)
        },
        async createFolder(context, payload) {
            const data = {
                parentFolderId: payload.parentFolderId,
                folderName: payload.folderName,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/createFolder`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async shareContent(context, payload) {
            const params = {
                moduleName: 'leads',
                emails: payload.emails,
                shareMessage: payload.shareMessage,
                content: payload.content,
                accessType: payload.accessType,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/shareContent`
            return await axios.get(link, { params }).then((r) => {
                return r.data
            })
        },
        async renameContent(context, payload) {
            const data = {
                newName: payload.newName,
                content: payload.content,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/renameContent`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async moveContent(context, payload) {
            const data = {
                destinationFullPath: payload.destinationFullPath,
                content: payload.content,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/moveContent`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async moveContentsBatch(context, payload) {
            const data = {
                entries: payload.entries,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/moveContentsBatch`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async deleteContent(context, payload) {
            const data = {
                content: payload.content,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/deleteContent`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async deleteContentsBatch(context, payload) {
            const data = {
                entries: payload.entries,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/deleteContentsBatch`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        async uploadFiles(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/mainFolders/${payload.zohoId}/uploadFiles`

            let data = new FormData()

            for (var i = 0; i < payload.filelist.length; i++) {
                data.append('files[]', payload.filelist[i])
            }
            data.append('parentFolderId', payload.parentFolderId)

            let config = {
                header: {
                    'content-type': 'multipart/form-data',
                },
            }

            return await axios.post(link, data, config).then((r) => {
                return r.data
            })
        },
    },
}
