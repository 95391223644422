import axios from 'axios'
export default {
    namespaced: true,

    state: {
        user: {
            is_authorized: false,
        },
        additionalInfo: {
            dealRootFolderExists: true,
            leadRootFolderExists: true,
        },
    },

    getters: {
        user(state) {
            return state.user
        },
        additionalInfo(state) {
            return state.additionalInfo
        },
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setAdditionalInfo(state, payload) {
            state.additionalInfo = payload
        },
    },

    actions: {
        setUser(context, payload) {
            context.commit('setUser', payload)
        },
        async fetchUser(context) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/forPlugin`
            return await axios.get(link).then((res) => {
                context.commit('setUser', res.data.user.data)
                context.commit('setAdditionalInfo', res.data.additionalInfo)
            })
        },
        async setDealRootFolder(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/setDealRootFolder`
            await axios
                .post(link, {
                    folderId: payload.folderId,
                    folderName: payload.folderName,
                })
                .then((res) => {
                    context.dispatch('fetchUser')
                })
        },
        async setLeadRootFolder(context, payload) {
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/setLeadRootFolder`
            await axios
                .post(link, {
                    folderId: payload.folderId,
                    folderName: payload.folderName,
                })
                .then((res) => {
                    context.dispatch('fetchUser')
                })
        },
    },
}
