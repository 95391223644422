import axios from 'axios'

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        async fetchFolderInfo({}, payload) {
            var params = {
                folderId: payload.folderId,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${payload.zapikey}/getFolderInfo`
            return await axios.get(link, { params }).then((response) => response)
        },
        async fetchFolderList({}, payload) {
            var params = {
                folderId: payload.folderId,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${payload.zapikey}/getFolderList`
            return await axios.get(link, { params }).then((response) => response)
        },
        async fetchParentFolderList({}, payload) {
            var params = {
                folderId: payload.folderId,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${payload.zapikey}/getFolderListOfParent`
            return await axios.get(link, { params }).then((response) => response)
        },
        async createFolder({}, payload) {
            var params = {
                parentFolderId: payload.parentFolderId,
                folderName: payload.folderName,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${payload.zapikey}/createFolder`
            return await axios.get(link, { params }).then((response) => response)
        },
    },
}
