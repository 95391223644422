import axios from 'axios'

export default {
    namespaced: true,

    state: {
        selectedFolderToMove: null,
    },

    getters: {
        selectedFolderToMove(state) {
            return state.selectedFolderToMove
        },
    },

    mutations: {
        setSelectedFolderToMove(state, payload) {
            state.selectedFolderToMove = payload
        },
    },

    actions: {
        async setConvertedLeadsLocation(context, payload) {
            const data = {
                folderId: payload.folderId,
            }
            var link = `${process.env.VUE_APP_BACKEND_API_URL}/api/users/${context.rootGetters['zoho/zapikey']}/setConvertedLeadsLocation`
            return await axios.post(link, data).then((r) => {
                return r.data
            })
        },
        setSelectedFolderToMove(context, payload) {
            context.commit('setSelectedFolderToMove', payload)
        },
    },
}
