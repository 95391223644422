<template>
    <router-view />
</template>

<style>
</style>

<script>
export default {
    name: "App",
    computed: {},
    async mounted() {
        // setInterval(() => {
        //     var reloadPage = localStorage.getItem("reloadPage");
        //     console.log(reloadPage);
        // }, 1000);
    },
};
</script>
