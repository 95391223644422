import axios from 'axios'

export default {
    namespaced: true,

    state: {
        user: {},
        isAdmin: 'inquiring', // inquiring, yes or no
        superadminEmail: null,
        zapikey: null,
    },

    getters: {
        user(state) {
            return state.user
        },
        isAdmin(state) {
            return state.isAdmin
        },
        superadminEmail(state) {
            return state.superadminEmail
        },
        zapikey(state) {
            return state.zapikey
        },
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setIsAdmin(state, payload) {
            state.isAdmin = payload
        },
        setSuperadminEmail(state, payload) {
            state.superadminEmail = payload
        },
        setZapiKey(state, payload) {
            state.zapikey = payload
        },
    },

    actions: {
        async initializeZohoApp() {
            return await ZOHO.embeddedApp.init()
        },

        async fetchZohoSuperadminEmail(context) {
            var superadminEmail = await ZOHO.CRM.API.getOrgVariable('zohocrm2dropbox__Super_Admin_Email').then((response) => response.Success.Content)

            context.commit('setSuperadminEmail', superadminEmail)

            return superadminEmail
        },

        async fetchZohoCurrentUser(context) {
            var user = await ZOHO.CRM.CONFIG.getCurrentUser()
            context.dispatch('setUser', user)
            context.dispatch('setIsAdmin', null)
            return user
        },

        async fetchZohoZapiKey(context) {
            var fetchZapiKeyPayload = {}
            fetchZapiKeyPayload['nameSpace'] = 'osc1.zohocrm2dropbox'

            var zapikey = await ZOHO.CRM.CONNECTOR.invokeAPI('crm.zapikey', fetchZapiKeyPayload).then((response) => JSON.parse(response).response)
            context.commit('setZapiKey', zapikey)

            return zapikey
        },

        setUser(context, payload) {
            context.commit('setUser', payload)
        },
        setIsAdmin(context) {
            var payload = context.state.user.users[0].email == context.rootState.zoho.superadminEmail || context.state.user.users[0].profile['name'] == 'Administrator' ? 'yes' : 'no'
            context.commit('setIsAdmin', payload)
        },
        revokeToken(context) {
            const url = process.env.VUE_APP_BACKEND_API_URL + '/api/users/' + context.getters.zapikey + '/revokeToken'
            axios.get(url).then(() => {
                context.dispatch('pluginUser/fetchUser', null, { root: true })
            })
        },
    },
}
