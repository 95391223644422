import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: "/settings",
        name: "settings",
        component: () => import("../views/Settings.vue"),
    },
    {
        path: "/dropboxauth/success",
        name: "dropboxauth.success",
        component: () => import("../views/DropBoxAuthSuccess.vue"),
    },
    {
        path: "/deals-widget",
        name: "widgets.deals",
        component: () => import("../views/Deals/Widget.vue"),
    },
    {
        path: "/leads-widget",
        name: "widgets.leads",
        component: () => import("../views/Leads/Widget.vue"),
    },
    {
        path: "/test",
        name: "test",
        component: () => import("../views/Test.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
